<template>
  <svg
    class="map country brazil"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 264.9 434.2"
  >
    <g class="hover">
      <path
        d="M143.9,429.8l-3-.2h-5l-6-13.6,3.1,2.8,4.3,4.6,7.8,3.7,7.3,1.5-.8,3-4.4.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M113.4,222.8l11.3,10.4,4.6,1,7.3,4.8,5.9,2.5,1.1,2.8-4.2,9.8,5.8,1.7,6.3,1,4.2-1,4.3-5,.3-5.6,2.6-1.3,3.2,3.8.4,5.1-4.2,3.5-3.3,2.6-5.3,6.3-6,8.7-.5,5.2-.4,6.6,1.2,6.4-.9,1.4.4,4.1.3,3.4,7.8,5.5.2,4.4,3.9,2.8.3,3.1-3.3,8.2-7,3.5-10.2,1.3-6-.7,2.1,3.9.1,4.7,1.8,3.2-2.5,2.3-5.1.9-5.6-2.4-1.5,1.7,2.5,6.3,4,1.9,2.3-2,2.5,3.3-4.2,2-2.9,4,1.2,6.3-.1,3.4h-4.8l-3,3.2.1,4.8,6.5,4.6,5.2,1.2.2,5.7-4.6,3.5-.6,7.3-3.5,2.4-.9,2.9,4.2,6.5,4.6,3.5-2.1-.3-4.9-1-12.1-.8-3.5-3.6-1.9-4.6-3.1.4-2.6-2.3-3.1-6.5,2.7-2.8.1-3.9-1.8-3.2.7-5.4-1.1-8.3L97,368.2l1.8-1.2-1.4-2.4-2.8-1.2.8-2.7-3.1-2.4L88.6,351l1.7-1.3L87,341.9l-.8-6.5-.2-5.7,2.5-2.3-3.3-6.3-1.6-5.8,3-4.2-1.4-5.4,1.6-6.2-1.4-5.9-1.6-1.2-4.9-11.1,2.1-6.6-1.7-6.2.9-5.9,2.6-6,3.3-4-2-2.5.8-2.1-1.6-10.7,5.6-3.1,1.2-6.7-.9-1.6,4-5.8,7.5,1.6,3.7,4.6,1.6-5.2,6.4.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M137.3,209.5l-.3-2-5.4-3.3-5.2-.1-9.6,1.9-2.1,5.6.2,3.5-1.5,7.7-1-1.4-6.4-.3-1.6,5.2-3.7-4.6-7.5-1.6-4,5.8-3.9.9-3.1-8.9-3.7-7.2,1.1-6.2-3.2-2.7-1.2-4.6L72,192.8l2.9-6.9L72,180.5l1.1-2.2-1.2-2.4,1.9-3.2-.3-5.4v-4.6l1.1-2.1-5.5-10.4,4.2.6,2.9-.2,1.1-1.9,4.8-2.6,2.9-2.4,7.3-1.1-.4,4.8.9,2.5-.3,4.3,6.5,5.7,6.4,1.1,2.3,2.4,3.9,1.3,2.5,1.8h3.5l3.4,1.9.5,3.7,1.2,1.9.3,2.7-1.7.1,2.8,7.5,10.7.3-.5,3.7.8,2.5,3.2,1.8,1.7,4-.6,5.1-1.3,2.8.8,3.6-1.6,1.4Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        class="brazil"
        d="M140.6,67.7l3.1.6.6-1.4-1-1.2.6-1.9,2.3.6,2.7-.7,3.2,1.4,2.5,1.3,1.7-1.7,1.3.2.8,1.8,2.7-.4,2.2-2.5,1.8-4.7,3.4-5.9,2-.3,1.3,3.6,3,11.2,3.1,1.1.1,4.4-4.3,5.3,1.7,1.9,10.1,1,.2,6.5,4.3-4.2,7.1,2.3,9.5,3.9,2.8,3.7-.9,3.6,6.6-2,11,3.4,8.5-.2,8.4,5.3,7.4,7.2,4.4,1.8,4.8.3,2.1,2,2,8.2,1.1,3.9-2.1,10.6-2.7,4.2-7.7,8.9-3.4,7.3-4,5.5-1.4.2-1.3,4.7.9,12-1.1,9.9-.3,4.2-1.6,2.6-.5,8.6-5.2,8.3-.5,6.7-4.3,2.7-1.1,3.9h-6l-8.5,2.4-3.7,2.9-6,1.9-6.1,5.1-4.1,6.4-.3,4.8,1.3,3.5-.3,6.5L194,264l-3.4,3.6-4.5,11.3-4,5-3.2,3.1-1.5,6.1-2.9,3.6-2.1-3.6,1.8-3.1-3.8-4.3-4.8-3.6-6.3-4.1-1.9.2-6.3-5-3.4.7,6-8.7,5.3-6.3,3.3-2.6,4.2-3.5-.4-5.1-3.2-3.8-2.6,1.3.7-3.7.3-3.8-.3-3.6-2.1-1.1-2,1-2.1-.3-.8-2.4-1.1-5.9-1.2-1.9-3.9-1.8-2.2,1.3-5.9-1.3-.4-8.7-2-3.5,1.6-1.4-.8-3.6,1.3-2.8.6-5.1-1.7-4-3.2-1.8-.8-2.5.5-3.7-10.7-.3-2.8-7.5,1.7-.1-.3-2.7-1.2-1.9-.5-3.7-3.4-1.9h-3.5l-2.5-1.8-3.9-1.3-2.3-2.4L99,159.9l-6.5-5.7.3-4.3-.9-2.5.4-4.8L85,143.7l-2.9,2.4-4.8,2.6-1.1,1.9-2.9.2-4.2-.6-3.2,1.1-2.6-.7-.1-9.7-4.4,3.7-5-.1L51.5,141l-3.8-.3,1-2.8L45.4,134l-2.6-5.8,1.5-1.1-.2-2.8,3.4-1.8-.7-3.5,1.4-2.2.3-3,6.3-4.4,4.6-1.2.8-1,5.1.3,2.2-17.6.1-2.8-.9-3.6-2.6-2.4.1-4.7,3.2-1,1.1.7.2-2.5-3.3-.7v-4l11,.2,1.9-2.3,1.6,2.1,1,3.8,1.1-.8,3.1,3.4,4.4-.4,1.1-2,4.2-1.5,2.4-1.1.7-2.7,4.1-1.8-.3-1.4-4.8-.5-.7-4.1.3-4.3L94,53.9l1.1-.6,4.1.8,4.5,1.6,1.7-1.5,4.1-1,6.4-2.4,2.1-2.5-.7-1.8,3-.2,1.2,1.4-.8,2.9,2,.9,1.2,3-1.6,2.3-1,5.4,1.4,3.3.3,3,3.5,3,2.8.3.6-1.3,1.8-.3,2.6-1.1,1.8-1.7,3.2.6,1.3-.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        class="colombia active"
        d="M59.2,4.3,55.5,5.4,53.9,8.6l-2.3,1.8-1.8,2.4-.9,4.6-1.8,3.8,2.9.4.6,2.9L51.8,26l.3,2.5-.7,2.4.1,1.4,1.4.5L54.1,35l7.3-.6,3.3.8,3.8,5.6,2.3-.7,4.1.3,3.2-.7,2,1.1-1.2,3.4-1.3,2.2L77,51l1.1,4.3,1.5,1.9.2,1.4-2.9,3.2,2,1.4,1.5,2.3L82,71.9l-1.1.8-1-3.8-1.6-2.1-1.9,2.3-11-.2v4l3.3.7-.2,2.5-1.1-.7-3.2,1-.1,4.7,2.6,2.4.9,3.6-.1,2.8-2.2,17.6-2.9-3.4-1.7-.1,3.5-6.6-4.4-3-3.4.6-2.1-1.1-3.1,1.7L47,94.7,43.5,88l-2.6-1.6-1.8-3.1-3.8-3-1.5.6-2.4-1.5-2.8-2.1-1.6,1-4.8-.9-1.4-2.8-1.1.1-5.6-3.6-.7-2,2.1-.5-.2-3.2L16.7,63l2.8-.4,2.5-4,2.2-3.4-2-1.5L23.4,50l-1.1-5.9,1.3-1.7L22.9,37l-2.2-3.5.9-3.1,1.8.5L24.5,29l-1.1-3.8.7-.9,2.9.2L31.5,20l2.4-.7.1-2.1,1.4-5.5,3.4-2.9,3.5-.2.6-1.3,4.4.5,4.6-3.2,2.3-1.4L57.1.1l2,.4,1.3,1.7L59.2,4.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M33.8,80.9l.8,4.9-1.7,4.1-6.1,6.8-6.7,2.5-3.4,5.6-.9,4.3-3.1,2.7-2.5-3.3-2.3-.7-2.3.5L5.3,106l1.6-1.5-.7-2.7L9.1,97,7.8,94.2l-2.1,3L2.2,94.3l1.1-1.8-1-5.8,2-1,1-4,2.1-4.1L7.1,75l3.1-1.4,3.9-2.5,5.6,3.6,1.1-.1,1.4,2.8,4.8.9,1.6-1,2.8,2.1,2.4,1.5Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M137.7,41.6l-1,5.8L133.2,49l.3,1.5-1.1,3.4,2.4,4.6h1.8l.7,3.6,3.3,5.6-1.3.3-3.2-.6-1.8,1.7-2.6,1.1-1.8.3-.6,1.3-2.8-.3-3.5-3-.3-3-1.4-3.3,1-5.4,1.6-2.3-1.2-3-2-.9.8-2.9-1.2-1.4-3,.2-3.7-4.8,1.6-1.8v-3l3.5-1,1.4-1.2-1.8-2.4.5-2.3,4.7-3.8,3.6,2.4,3.3,4.1.1,3.4,2.1.1,3,3.1,2.1,2.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M65.3,107.5l-5.1-.3-.8,1-4.6,1.2-6.3,4.4-.3,3L46.8,119l.7,3.5-3.4,1.8.2,2.8-1.5,1.1,2.6,5.8,3.3,3.9-1,2.8,3.8.3,2.3,3.5,5,.1,4.4-3.7.1,9.7,2.6.7,3.2-1.1,5.5,10.4-1.1,2.1v4.6l.3,5.4-1.9,3.2,1.2,2.4L72,180.5l2.9,5.4L72,192.8l-1.1,3.3-2.8,1.6L62.2,194l-.8-2.6L49.7,185,39,177.9,34.3,174l-2.8-5.3.8-1.9-5.4-8.4-6.4-11.8-6-12.8-2.4-3-2-4.7-4.6-4.2-4.1-2.6,1.7-2.8-3-6.2,1.7-4.5,4.4-4,.7,2.7L5.3,106l.3,2.3,2.3-.5,2.3.7,2.5,3.3,3.1-2.7.9-4.3,3.4-5.6,6.7-2.5,6.1-6.8,1.7-4.1-.8-4.9,1.5-.6,3.8,3,1.8,3.1L43.5,88,47,94.7l4.2.9,3.1-1.7L56.4,95l3.4-.6,4.4,3L60.7,104l1.7.1,2.9,3.4Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M137.3,209.5l2,3.5.4,8.7,5.9,1.3,2.2-1.3,3.9,1.8,1.2,1.9,1.1,5.9.8,2.4,2.1.3,2-1,2.1,1.1.3,3.6-.3,3.8-.7,3.7-.3,5.6-4.3,5-4.2,1-6.3-1-5.8-1.7,4.2-9.8-1.1-2.8-5.9-2.5-7.3-4.8-4.6-1-11.3-10.4,1.5-7.7-.2-3.5,2.1-5.6,9.6-1.9,5.2.1,5.4,3.3.3,2Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M155.8,43l-3.1,5.5.3,4.4,2.2,3.8-1.1,2.7-.5,3-1.5,2.7-3.2-1.4-2.7.7-2.3-.6-.6,1.9,1,1.2-.6,1.4-3.1-.6-3.3-5.6-.7-3.6h-1.8l-2.4-4.6,1.1-3.4-.3-1.5,3.5-1.6,1-5.8,6.8,1.3.6-1.2,4.6-.5,6.1,1.8Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M174.5,296.7l-1.6,4.1-5.4,3.5-4.2-1.3-2.8.7L155,301l-3.6.2-3.9-3.6-.4-4.1.9-1.4-1.2-6.4.4-6.6.5-5.2,3.4-.7,6.3,5,1.9-.2,6.3,4.1,4.8,3.6,3.8,4.3-1.8,3.1,2.1,3.6Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M123.5,26.2,118.8,30l-.5,2.3,1.8,2.4-1.4,1.2-3.5,1v3l-1.6,1.8,3.7,4.8.7,1.8-2.1,2.5-6.4,2.4-4.1,1-1.7,1.5-4.5-1.6-4.1-.8-1.1.6,2.5,1.6-.3,4.3.7,4.1,4.8.5.3,1.4-4.1,1.8-.7,2.7-2.4,1.1-4.2,1.5-1.1,2-4.4.4L82,71.9l-1.6-6.4-1.5-2.3-2-1.4,2.9-3.2-.2-1.4-1.5-1.9L77,51l.6-4.6,1.3-2.2,1.2-3.4-2-1.1-3.2.7-4.1-.3-2.3.7-3.8-5.6-3.3-.8-7.3.6-1.2-2.2-1.4-.5-.1-1.4.7-2.4L51.8,26l-1.2-1.5L50,21.6l-2.9-.4,1.8-3.8.9-4.6,1.8-2.4,2.3-1.8,1.6-3.2,3.7-1.1L59,5.8l-3.4.8,1.7,2.9L57,12.9l-2.7,3.7,1.9,5.1,2.5-.4,1.5-4.7-1.7-2.2V9.5l7.2-2.6-.6-3,2.1-2.1,1.7,4.6,4,.1L76.3,10l.2,2.2h5l6.1-.6,3.1,2.8,4.2.8,3.3-2,.1-1.6,7.1-.4,6.7-.1L107.2,13l1.8,3.1,4.5.4,4.2,3.2.7,5.1,2.9-.1,2.2,1.5Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M129.9,416l6,13.6h5l3,.2-.5,2.4-3.2,1.9-2.4-.2-3.1-.5-4.2-1.8-5.4-.9-7.5-3.4-6.4-3.2-9.8-6.9,4.6,1.3,8.6,4.1,7.3,2.2,1.2-2.8-.3-4.2,3.4-2.6Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M89.2,225.9l.9,1.6-1.2,6.7-5.6,3.1L84.9,248l-.8,2.1,2,2.5-3.3,4-2.6,6-.9,5.9,1.7,6.2-2.1,6.6,4.9,11.1,1.6,1.2,1.4,5.9-1.6,6.2,1.4,5.4-3,4.2,1.6,5.8,3.3,6.3L86,329.7l.2,5.7.8,6.5,3.3,7.8L88.6,351l3.7,7.3,3.1,2.4-.8,2.7,2.8,1.2,1.4,2.4L97,368.2l1.8,3.7,1.1,8.3-.7,5.4,1.8,3.2-.1,3.9-2.7,2.8,3.1,6.5,2.6,2.3,3.1-.4,1.9,4.6,3.5,3.6,12.1.8,4.9,1h-4.3l-1.7,1.5-3.4,2.2,1.7,5.7-1.9.1-6.3-1.9-7.5-4.3-7.6-3.5-3.2-3.9-.3-3.6L90.7,402l-5-10.6-.2-6,3.4-4.8-8.3-1.9,2.7-5.5-2-10.5,6.5,2.2L86,351.8l-4-1.7,1,7.9-3.5-.9-1.5-9-2-11.8,1.1-4.4-3.3-6.2-2.4-7.2,2.2-.3.6-10.3,1.2-10.3.1-9.5-3.3-9.7.6-5.3-2.1-7.9,1.8-7.8L71.6,245V231.6l.1-14.3-1.5-10.5-2.1-9.1,2.8-1.6,1.1-3.3,3.2,4.4,1.2,4.6,3.2,2.7-1.1,6.2,3.7,7.2,3.1,8.9Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <path
        d="M152.1,65.1l1.5-2.8.5-2.9,1-2.7L153,52.9l-.3-4.4,3.1-5.5,1.9.7,4.1,1.5,5.9,5.4.8,2.6-3.4,5.9-1.8,4.7-2.2,2.5-2.7.4-.8-1.8-1.3-.2-1.7,1.7-2.5-1.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></path>
      <rect
        x="148.3"
        y="302.2"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      ></rect>
    </g>
    <g class="pin" transform="translate(35 35)">
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
    </g>
  </svg>
</template>
